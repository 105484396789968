import React from 'react'
import { navigate } from "@reach/router" 

const PageNotFound = () => {
  if(typeof window !== 'undefined') {
    let currentLocale = localStorage.getItem('currentLocale') || 'en'
    localStorage.setItem('currentLocale', currentLocale)
    navigate(`/${currentLocale}/404`)
    location.reload()
  }

  return ( <></> )
}

export default PageNotFound